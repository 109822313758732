import React, { Component } from 'react';
import { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

//am4core.useTheme(am4themes_animated);
am4core.options.queue = true;

interface AppProps {
  
}

interface AppState {
  input1: number;
  input2: number;
  input3: number;
}

class App extends Component<AppProps, AppState> {
  protected chart;

  constructor(props) {
    super(props);
    this.state = {
      input1: 366,
      input2: 100,
      input3: 100
    };
  }

  componentDidMount() {
    //this.drawAll();
  }

  drawAll() {
    this.drawFirst();
    this.drawSecond();
    this.drawThird();    
  }

  async drawFirst() {
    let chart = am4core.create("chartdiv1", am4charts.XYChart);

    chart.paddingRight = 20;

    let data : object[] = [];
    let visits = 10;
    for (let i = 1; i < this.state.input1; i++) {
      visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      data.push({ date: new Date(2018, 0, i), name: "name" + i, value: visits });
    }

    chart.data = data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (valueAxis.tooltip) { valueAxis.tooltip.disabled = true; }
    valueAxis.renderer.minWidth = 35;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";

    series.tooltipText = "{valueY.value}";
    chart.cursor = new am4charts.XYCursor();

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    //this.chart = chart;
  }

  async drawSecond() {
    // Create chart instance
    let chart = am4core.create("chartdiv2", am4charts.XYChart);
    this.drawStacked(chart, this.state.input2, 'column')
  }

  async drawThird() {
    // Create chart instance
    let chart = am4core.create("chartdiv3", am4charts.XYChart);
    this.drawStacked(chart, this.state.input3, 'line')
  }

  drawStacked(chart, count, serieType) {
    let data : object[] = [];
    let visits = 10;
    for (let i = 1; i < count; i++) {
      visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      let date = new Date(2018, 0, i);
      data.push({
        date: date.toString(),
        "europe": Math.round(Math.random() * 10),
        "namerica": Math.round(Math.random() * 10),
        "asia": Math.round(Math.random() * 10),
        "lamerica": Math.round(Math.random() * 10),
        "meast": Math.round(Math.random() * 10),
        "africa": Math.round(Math.random() * 10)
      });
    }
    console.log('data done');

    // Add data
    chart.data = data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.grid.template.location = 0;


    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;

    this.createSeries(chart, "europe", "Europe", serieType);
    this.createSeries(chart, "namerica", "North America", serieType);
    this.createSeries(chart, "asia", "Asia-Pacific", serieType);
    this.createSeries(chart, "lamerica", "Latin America", serieType);
    this.createSeries(chart, "meast", "Middle-East", serieType);
    this.createSeries(chart, "africa", "Africa", serieType);

    // Legend
    chart.legend = new am4charts.Legend();

    chart.cursor = new am4charts.XYCursor();
    //chart.cursor.snapToSeries = series;
    chart.cursor.xAxis = categoryAxis;
  }

  // Create series
  createSeries(chart, field, name, serieType) {
    
    // Set up series
    let series;
    if (serieType == 'column') {
      series = chart.series.push(new am4charts.ColumnSeries());
    } else if (serieType == 'line') {
      series = chart.series.push(new am4charts.LineSeries());
    }
    series.name = name;
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "date";
    series.sequencedInterpolation = true;
    
    // Make it stacked
    series.stacked = true;
    
    series.name = name;
    //series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.tooltipText = "{valueY.value}";

    // Configure columns
    //series.columns.template.width = am4core.percent(60);
    //series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
    
    // Add label
    //let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    //labelBullet.label.text = "{valueY}";
    //labelBullet.locationY = 0.5;
    //labelBullet.label.hideOversized = true;
    
    return series;
  }


  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <>
        <a onClick={() => this.drawAll()}>Reload All</a>
        <h3>Line chart</h3>
        <input type="text" value={this.state.input1} onChange={evt => this.setState({input1: parseInt(evt.target.value)})} /> <a onClick={() => this.drawFirst()}>Reload graph</a>
        <div id="chartdiv1" style={{ width: "100%", height: "300px" }}>Loading...</div>
        <h3>Stacked column chart</h3>
        <input type="text" value={this.state.input2} onChange={evt => this.setState({input2: parseInt(evt.target.value)})} /> <a onClick={() => this.drawSecond()}>Reload graph</a>
        <div id="chartdiv2" style={{ width: "100%", height: "300px" }}>Loading...</div>
        <h3>Stacked line chart</h3>
        <input type="text" value={this.state.input3} onChange={evt => this.setState({input3: parseInt(evt.target.value)})} /> <a onClick={() => this.drawThird()}>Reload graph</a>
        <div id="chartdiv3" style={{ width: "100%", height: "300px" }}>Loading...</div>
      </>
    );
  }
}

export default App;
